import React from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  faEye,
  faGavel,
  faFileInvoice,
  faHeadset,
  faExclamationCircle,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Styled from "styled-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faEyeProps = faEye as IconProp;
const faGavelProps = faGavel as IconProp;
const faFileInvoiceProps = faFileInvoice as IconProp;
const ffaHeadsetProps = faHeadset as IconProp;
const faExclamationCircleProps = faExclamationCircle as IconProp;
const faHouseUserProps = faHouseUser as IconProp;

const StyledIcon = Styled.img`
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
`;

export const MenuData = [
  {
    title: "common:menu:home",
    path: "/home",
    tab: "home",
  },
  {
    title: "common:menu:preview",
    path: "/preview",
    tab: "preview",
    subNav: [
      {
        title: "common:menu:home",
        path: "/preview/home",
      },
      {
        // title: "common:landingPage:category:diamonds",
        title: "common:landingPage:category:septTitle",
        path: "/preview/diamond",
      },
      // {
      //   title: "common:landingPage:category:jewellery",
      //   path: "/preview/jewellery",
      // },
    ],
  },
  {
    title: "common:menu:auction",
    path: "/auction",
    tab: "auction",
    isAuction: true,
    subNav: [
      {
        title: "common:menu:home",
        path: "/auction/home",
      },
      {
        // title: "common:landingPage:category:diamonds",
        title: "common:landingPage:category:septTitle",
        path: "/auction/diamond",
      },
      // {
      //   title: "common:landingPage:category:jewellery",
      //   path: "/auction/jewellery",
      // },
    ],
  },
  {
    title: "365 DAYS",
    path: "/365products",
    tab: "productauction",
    isProductAuction: true,
    subNav: [
      {
        title: "common:landingPage:category:diamonds",
        path: "/365products",
      },
      {
        title: "common:landingPage:category:jewellery",
        path: "/365products/jewellery",
      },
    ],
  },
  {
    title: "common:menu:mypage",
    path: "/my-page",
    tab: "mypage",
    isAuction: true,
  },
  {
    title: "common:menu:invoice",
    path: "/invoice",
    tab: "invoice",
    subNav: [
      {
        title: "common:menu:home",
        path: "/invoice/home",
      },
      {
        title: "common:landingPage:category:diamonds",
        path: "/invoice/diamond",
      },
      {
        title: "common:landingPage:category:jewellery",
        path: "/invoice/jewellery",
      },
      {
        title: "common:newCommanTrans:productDaysAuctionInvoices",
        path: "/invoice/365DaysAuctionsinvoice",
        resize: true,
      },
    ],
  },
  {
    title: "common:menu:inquiry",
    path: "/inquiry",
    icon: <FontAwesomeIcon icon={ffaHeadsetProps} style={{ color: "white" }} />,
  },
];

export const SubDoaminMenuData = [
  {
    title: "common:menu:home",
    path: "/home",
    tab: "home",
  },
  {
    title: "common:menu:preview",
    path: "/preview",
    tab: "preview",
    subNav: [
      {
        title: "common:menu:home",
        path: "/preview/home",
      },
      {
        // title: "common:landingPage:category:septTitle",
        title: "common:landingPage:category:septTitle",
        path: "/preview/diamond",
      },
      // {
      //   title: "common:landingPage:category:jewellery",
      //   path: "/preview/jewellery",
      // },
    ],
  },
  {
    title: "common:menu:auction",
    path: "/auction",
    tab: "auction",
    isAuction: true,
    subNav: [
      {
        title: "common:menu:home",
        path: "/auction/home",
      },
      {
        // title: "common:landingPage:category:diamonds",
        title: "common:landingPage:category:septTitle",
        path: "/auction/diamond",
      },
      // {
      //   title: "common:landingPage:category:jewellery",
      //   path: "/auction/jewellery",
      // },
    ],
  },
  {
    title: "common:menu:mypage",
    path: "/my-page",
    tab: "mypage",
    isAuction: true,
  },
  {
    title: "common:menu:invoice",
    path: "/invoice",
    tab: "invoice",
    subNav: [
      {
        title: "common:menu:home",
        path: "/invoice/home",
      },
      {
        title: "common:landingPage:category:diamonds",
        path: "/invoice/diamond",
      },
      {
        title: "common:landingPage:category:jewellery",
        path: "/invoice/jewellery",
      },
    ],
  },
  {
    title: "common:menu:inquiry",
    path: "/inquiry",
    icon: <FontAwesomeIcon icon={ffaHeadsetProps} style={{ color: "white" }} />,
  },
];

export const menuListref = [
  {
    key: "preview",
    submenu: [
      { text: "common:menu:home", url: "/preview/home" },
      {
        text: "common:landingPage:category:diamonds",
        url: "/preview/diamond",
      },
      {
        text: "common:landingPage:category:jewellery",
        url: "/preview/jewellery",
      },
    ],
  },
  {
    key: "auction",
    submenu: [
      {
        title: "common:menu:home",
        path: "/auction/home",
      },
      {
        title: "common:landingPage:category:diamonds",
        path: "/auction/diamond",
      },
      {
        title: "common:landingPage:category:jewellery",
        path: "/auction/jewellery",
      },
    ],
  },
  {
    key: "productauction",
    submenu: [
      {
        title: "common:landingPage:category:diamonds",
        path: "/365products",
      },
      {
        title: "common:landingPage:category:jewellery",
        path: "/365products/jewellery",
      },
    ],
  },
  {
    key: "incoive",
    submenu: [
      {
        title: "common:menu:home",
        path: "/invoice/home",
      },
      {
        title: "common:landingPage:category:diamonds",
        path: "/invoice/diamond",
      },
      {
        title: "common:landingPage:category:jewellery",
        path: "/invoice/jewellery",
      },
      {
        title: "common:newCommanTrans:productDaysAuctionInvoices",
        path: "/invoice/365DaysAuctionsinvoice",
        resize: true,
      },
    ],
  },
];
export const SideMenuDataWithout365DAYs = [
  {
    title: "common:menu:home",
    path: "/home",
    icon: (
      <FontAwesomeIcon icon={faHouseUserProps} style={{ color: "white" }} />
    ),
  },
  {
    title: "common:menu:preview",
    path: "/preview",
    icon: <FontAwesomeIcon icon={faEyeProps} style={{ color: "white" }} />,
    iconClosed: (
      <ArrowDropDownIcon
        fontSize="small"
        style={{ fontSize: 14, color: "white", cursor: "pointer" }}
      />
    ),
    iconOpened: (
      <ArrowDropUpIcon
        fontSize="small"
        style={{ fontSize: 14, color: "white", cursor: "pointer" }}
      />
    ),
    subNav: [
      {
        title: "common:menu:home",
        path: "/preview/home",
        icon: <StyledIcon src="/images/svgimages/auction.svg" />,
      },
      {
        title: "common:landingPage:category:diamonds",
        path: "/preview/diamond",
        icon: <StyledIcon src="/images/svgimages/diamond.svg" />,
      },
      {
        title: "common:landingPage:category:jewellery",
        path: "/preview/jewellery",
        icon: <StyledIcon src="/images/svgimages/jewllery.svg" />,
      },
    ],
  },
  {
    title: "common:menu:auction",
    path: "/auction",
    icon: <FontAwesomeIcon icon={faGavelProps} style={{ color: "white" }} />,
    isAuction: true,
    iconClosed: (
      <ArrowDropDownIcon
        fontSize="small"
        style={{ fontSize: 14, color: "white", cursor: "pointer" }}
      />
    ),
    iconOpened: (
      <ArrowDropUpIcon
        fontSize="small"
        style={{ fontSize: 14, color: "white", cursor: "pointer" }}
      />
    ),
    subNav: [
      {
        title: "common:menu:home",
        path: "/auction/home",
        icon: <StyledIcon src="/images/svgimages/auction.svg" />,
      },
      {
        title: "common:landingPage:category:diamonds",
        path: "/auction/diamond",
        icon: <StyledIcon src="/images/svgimages/diamond.svg" />,
      },
      {
        title: "common:landingPage:category:jewellery",
        path: "/auction/jewellery",
        icon: <StyledIcon src="/images/svgimages/jewllery.svg" />,
      },
    ],
  },
  {
    title: "common:menu:invoice",
    path: "/invoice",
    icon: (
      <FontAwesomeIcon icon={faFileInvoiceProps} style={{ color: "white" }} />
    ),
    iconClosed: (
      <ArrowDropDownIcon
        fontSize="small"
        style={{ fontSize: 14, color: "white", cursor: "pointer" }}
      />
    ),
    iconOpened: (
      <ArrowDropUpIcon
        fontSize="small"
        style={{ fontSize: 14, color: "white", cursor: "pointer" }}
      />
    ),
    subNav: [
      {
        title: "common:menu:home",
        path: "/invoice/home",
        icon: <StyledIcon src="/images/svgimages/auction.svg" />,
      },
      {
        title: "common:landingPage:category:diamonds",
        path: "/invoice/diamond",
        icon: <StyledIcon src="/images/svgimages/diamond.svg" />,
      },
      {
        title: "common:landingPage:category:jewellery",
        path: "/invoice/jewellery",
        icon: <StyledIcon src="/images/svgimages/jewllery.svg" />,
      },
    ],
  },
  {
    title: "common:menu:inquiry",
    path: "/inquiry",
    icon: <FontAwesomeIcon icon={ffaHeadsetProps} style={{ color: "white" }} />,
  },
  {
    title: "common:menu:mypage",
    path: "/my-page",
    icon: (
      <FontAwesomeIcon
        icon={faExclamationCircleProps}
        style={{ color: "white" }}
      />
    ),
  },
];
