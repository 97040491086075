import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, useMediaQuery } from "@material-ui/core";
import {
  StyledActionBarContainer,
  StyledActionSecondBarContainer,
  StyledActionsContainer,
  StyledAmountHeading,
  StyledContainer,
  StyledDropDown,
  StyledMypageGrid,
  StyledRoundButton,
} from "./MypageStyle";
import MypageTable from "./components/MypageTable";
import {
  StyledHeaderContainer,
  StyledCoreDataGridContainer,
  StyledSelectAllButtonHeader,
} from "../365Auction/component/DiamondProductStyle";
import SearchWithSuggestion from "./components/SearchWithSuggestion";
import {
  addOrRemoveWatchlist,
  exportProducts,
  getProductDetails,
  myPagePostRequest,
  refreshPage,
} from "./MypageApiService";
import CategoryDropDown, { StyledSelectorContainer } from "./components/CategoryDropDown";
import RefreshIcon from "@material-ui/icons/Refresh";
import PrebidFormModal from "./components/Details/PrebidFormModal";
import { StyledModal } from "../../common/materials/GenericStyleComponent";
import DoYouWishNegotiatePopup from "../pendingProductsTab/componets/DoYouWishNegotiatePopup";
import MypagePagination from "./components/Details/MypagePagination";
import { AppDispatch } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMypageItemPerPage,
  setMyPageItemPerPage,
} from "../../redux/slice/commanPagination/commanPaginationSlice";
import { RootState } from "../../redux/reducer";
import Alert from "../../common/elements/Alert";
import MyPageProductsGrid from "./components/grid/MyPageProductsGrid";
import FilterPopup from "./components/FilterPopup";
import ReadOnlyUserModal from "../../common/elements/ReadOnlyUserModal";
import { useHistory } from "react-router";

let isReadOnlyUser: any;
let filteredValues: any = "";
let filter: any = [];
let joinedAuctions: any = [];
let jwljoinedAuctionUserId: string;
let joinedAuctionUserId: string;

const BuyMypage = (props: any) => {
  let { t } = useTranslation();
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [isGridView, setIsGridView] = useState<boolean>(false);
  let [totalAmount, setTotalAmount] = useState<Number>(0);
  let [product, setProduct] = useState([]);
  let [selectedBuyCategory, setSelectedBuyValue] = useState("WatchList&Prebid");
  let [page, setPage] = useState(0);
  let [totalElements, setTotalElements] = useState(0);
  let [showPreBidModal, setShowPreBidModal] = useState(false);
  let [row, setRow] = useState("");
  const [showNegotiateModal, setShowNegotiateModal] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width: 850px)");
  let [exportIds, setexportIds] = useState<any[]>([]);
  const [openToast, setOpenToast] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showAlertMessage, setShowAlertMessage] = useState("");
  let [filters, setFilters] = useState();
  const [readOnly, setReadOnly] = useState(false);
  let [sort, setSort] = useState();
  const dispatch: AppDispatch = useDispatch();
  const [isPageChange, setIsPageChange] = useState(false);
  const [filterList, setFilterList] = React.useState<string>("");

  const [isGetAuctionProducts, setIsGetAuctionProducts] =
    useState<boolean>(false);

  let languageCode =
    localStorage.getItem("language") === "EN"
      ? "en"
      : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";

  let history = useHistory();

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectMypageItemPerPage(state)
  );

  useEffect(() => {
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    if (isReadOnlyUser) {
      setReadOnly(true);
    }
    // setFilterList("");
    localStorage.removeItem("myPagefilteredValues");
    joinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null || joinedAuctions?.length !== undefined) {
      let diamsArr = joinedAuctions?.filter((items: any) => items.category === "Diamonds");
      let jwlArr = joinedAuctions?.filter((items: any) => items.category === "Jewellery");

      if (jwlArr !== undefined || diamsArr !== undefined) {
        joinedAuctionUserId = diamsArr[0]?.auctionUserId;
        jwljoinedAuctionUserId = jwlArr[0]?.auctionUserId;
      }
    }

    return () => {
      localStorage.removeItem("myPagefilteredValues");
    };
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      getBuyProductList(0);
    }, 700);

    return () => clearTimeout(getData);
  }, [filters]);

  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  useEffect(() => {
    setIsLoading(true);
    setIsPageChange(!isPageChange);
    setPage(0);
    callApi(0);
  }, [selectItemPerPageValue, selectedBuyCategory]);

  const callApi = (payload: any) => {
    setPage(payload);
    let filter = null; // Initialize filter here

    if (
      localStorage.getItem("myPagefilteredValues") !== null &&
      localStorage.getItem("myPagefilteredValues") !== undefined
    ) {
      filteredValues = localStorage.getItem("myPagefilteredValues");

      try {
        filteredValues = JSON.parse(filteredValues); // Correct JSON parsing
      } catch (error) {
        console.error("Error parsing JSON:", error);
        filteredValues = null; // Handle JSON parsing error
      }

      if (filteredValues !== null && filteredValues.length > 0) {
        // Use a for loop to avoid modifying the array while iterating
        for (let index = filteredValues.length - 1; index >= 0; index--) {
          const item = filteredValues[index];
          if (item.name === "auctionId") {
            filteredValues.splice(index, 1);
          }
        }
        filter = filteredValues;
      }
      getBuyProductList(payload, filter);
    } else {
      getBuyProductList(payload);
    }
  };

  const getBuyProductList = async (pagenum: any, searching?: any) => {
    let pageCount = pagenum * selectItemPerPageValue;
    const { response, status } = await myPagePostRequest(
      pageCount,
      selectItemPerPageValue,
      selectedBuyCategory,
      filters,
      sort,
      searching
    );
    if (status === 200) {
      // setProduct(response.results?.filter((x: any) => renderFiltered(x)));
      if (response.additionalData.totalAmount !== undefined) {
        let total: any = new Intl.NumberFormat("us-en").format(
          Math.round(response.additionalData.totalAmount)
        );
        setTotalAmount(total);
      } else {
        setTotalAmount(0);
      }
      let requestData: any = [];

      for (var i = 0; i < response.results.length; i++) {
        let obj: any = response.results[i];
        if (obj?.rowColor !== undefined) {
          if (obj?.state === "Available") {
            switch (obj?.rowColor) {
              case "#FBE4D5":
                obj["colorFilter"] = 111;
                break;
              case "#FFEB9C":
                obj["colorFilter"] = 112;
                break;
              case "#FFFFFF":
                obj["colorFilter"] = 113;
                break;
              default:
                obj["colorFilter"] = 114;
                break;
            }
          } else {
            obj["colorFilter"] = 115;
          }
        }
        requestData.push(obj);
      }
      setProduct(() => requestData?.filter((x: any) => renderFiltered(x)));
      setTotalElements(response.totalElements);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const setItemsPerPageHandler = (itemsPerPage: number) => {
    setPage(0);
    dispatch(setMyPageItemPerPage({ itemsPerPage }));
  };

  const handlePrebid = async (id: string, data: any, isPrebid: boolean) => {
    let rowsData: any;
    const { response, status } = await getProductDetails(id);
    if (status === 200) {
      if (response.subItems) {
        var rows = [];

        for (var i = 0; i < response.subItems.length; i++) {
          let obj = response.subItems[i];
          if (obj.medias !== undefined) {
            for (var j = 0; j < obj.medias.length; j++) {
              rows.push(obj.medias[j]);
            }
          }
        }
        var objModify = response;
        objModify.medias = objModify.medias.concat(rows);
        rowsData = objModify;
      } else {
        rowsData = response;
      }
    }
    setRow(rowsData);
    setShowPreBidModal(true);
  };

  const handleWatchlist = async (
    auctionId: string | number,
    productId: string | number,
    isWatch: boolean
  ) => {
    let url = isWatch
      ? `${auctionId}/${productId}/watch`
      : `${auctionId}/${productId}/unwatch`;
    const { status } = await addOrRemoveWatchlist(url);
    if (status === 200) {
      const newArr: any = await product.map((obj: any) => {
        if (obj.perId === productId) {
          return {
            ...obj,
            watched: isWatch,
          };
        }
        return obj;
      });
      setProduct(newArr);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const exportExcelSheet = async () => {
    if (exportIds.length !== 0) {
      let ids = exportIds.toString();
      const { status } = await exportProducts(ids);
      if (status === 200) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      setOpenToast(true);
      setAlertType("error");
      setShowAlertMessage("Select product for export.");
      setTimeout(() => {
        setOpenToast(false);
      }, 2000);
    }
  };
  const selectAllInGridView = () => {
    if (exportIds.length === product.length) {
      setexportIds([]);
    } else {
      let arr: any = [];
      product.map((productVal: any) => {
        arr.push(productVal.perId);
      });
      setexportIds(arr);
    }
  };

  const selectItemInGridView = (id: any) => {
    let arr: any = [];
    if (exportIds.indexOf(id) !== -1) {
      arr = exportIds.filter((x: any) => x !== id);
    } else {
      arr = [...exportIds];
      arr.push(id);
    }
    setexportIds(arr);
  };

  const filterHandler = (isFilter: boolean, data: any) => {
    getBuyProductList(0, data);
  };

  const readOnlyUserModal = () => {
    return (
      <ReadOnlyUserModal
        isOpen={readOnly}
        onclose={() => {
          setReadOnly(false);
          history.replace("/home");
        }}
      />
    );
  };


  const renderSellandBuyHeader = () => {
    return (
      <StyledMypageGrid style={{ padding: "0 20px 5px" }}>
        <Grid item={true} xs={12}>
          <StyledHeaderContainer container={true} spacing={0}>
            <StyledCoreDataGridContainer container={true} spacing={0}>
              <StyledActionBarContainer
                container={true}
                spacing={0}
                className="coreActionBarContainer"
              >
                <StyledContainer>
                  {!isMobile ? (
                    <>
                      {isGridView ? (
                        <StyledSelectAllButtonHeader
                          onClick={selectAllInGridView}
                        >
                          {t("common:newCommanTrans:selectAll")}
                        </StyledSelectAllButtonHeader>
                      ) : null}
                    </>
                  ) : (
                    <StyledSelectAllButtonHeader onClick={selectAllInGridView}>
                      {t("common:newCommanTrans:selectAll")}
                    </StyledSelectAllButtonHeader>
                  )}
                  <StyledRoundButton onClick={refreshPage} style={{ marginRight: "10px" }}>
                    <RefreshIcon />
                  </StyledRoundButton>
                  <MypagePagination
                    setItemsPerPageHandler={setItemsPerPageHandler}
                  />
                </StyledContainer>
                <StyledContainer style={{ gap: "5px" }}>
                  <StyledSelectorContainer>
                    <select
                      name="sortingFilter"
                      onChange={handleChange}
                      value={filterList}
                      style={{ width: '125px' }}
                    >
                      <option value="" style={{ fontSize: "1em", cursor: "pointer" }}>
                        Select Filter
                      </option>
                      <option value="111" style={{ color: 'green', fontSize: "1em", cursor: "pointer" }}>
                        Winner
                      </option>
                      <option value="113" style={{ color: 'red', fontSize: "1em", cursor: "pointer" }}>
                        Bid Surpassed
                      </option>
                      <option value="112" style={{ color: 'black', fontSize: "1em", cursor: "pointer" }}>
                        Almost Sold
                      </option>
                    </select>
                  </StyledSelectorContainer>
                  <CategoryDropDown
                    setValue={setSelectedBuyValue}
                    isSellPage={false}
                  />
                </StyledContainer>
                <StyledContainer>
                  <StyledDropDown
                    src="/images/assets/iconDownload.png"
                    alt="exportsheet"
                    onClick={exportExcelSheet}
                    title="Download Export"
                    style={{ marginLeft: "10px" }}
                  />

                  {!isMobile ? (
                    <div
                      onClick={() => {
                        setIsGridView(!isGridView);
                      }}

                    >
                      {isGridView ? (
                        <StyledDropDown src="/images/assets/listViewIcon.png" className="icon" />
                      ) : (
                        <StyledDropDown src="/images/assets/gridViewIcon.png" className="icon" />
                      )}
                    </div>
                  ) : null}
                </StyledContainer>
              </StyledActionBarContainer>
            </StyledCoreDataGridContainer>
          </StyledHeaderContainer>
        </Grid>
      </StyledMypageGrid>
    );
  };

  const renderSearchBar = () => {
    return (
      <StyledMypageGrid
        style={{
          padding: "0px 20px 5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StyledActionSecondBarContainer container={true} spacing={0}>
          <Grid
            item={true}
            xs={12}
            lg={3}
            xl={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {filterList === "" ? <div style={{ display: "flex", gap: "10px" }}>
              <StyledAmountHeading>Total Amount:</StyledAmountHeading>
              <StyledAmountHeading className="totalAmount">
                {userCurrencySymbol} {totalAmount}
              </StyledAmountHeading>
            </div> : <></>}
          </Grid>
          <StyledActionsContainer item={true} xs={12} lg={9} xl={10}>
            <StyledContainer style={{ width: "25%" }}>
              <SearchWithSuggestion lable={"Search..."} setValue={setFilters} />
            </StyledContainer>
            <StyledContainer>
              <FilterPopup
                filteredpropduct={filterHandler}
                resetfilter={(page: any) => {
                  callApi(0);
                }}
                iswatchlist={selectedBuyCategory}
                isSellPage={false}
              />
            </StyledContainer>
          </StyledActionsContainer>
        </StyledActionSecondBarContainer>
      </StyledMypageGrid>
    );
  };
  const handleBidPopup = () => {
    setShowPreBidModal(false);
    callApi(page);
  };

  const renderPrebidPopup = () => {
    return (
      <PrebidFormModal
        {...props}
        open={showPreBidModal}
        handleClose={handleBidPopup}
        items={row}
        t={t}
        count={0}
        isReadOnlyUser={isReadOnlyUser}
        cancelPreBid={handleBidPopup}
        preBidAmountSaved={handleBidPopup}
      />
    );
  };

  const renderNegotiateModal = () => {
    return (
      <StyledModal
        open={showNegotiateModal}
        onClose={() => {
          setShowNegotiateModal(false);
        }}
      >
        <DoYouWishNegotiatePopup
          productDetails={row}
          closeModal={() => {
            setShowNegotiateModal(false);
          }}
        />
      </StyledModal>
    );
  };

  const handlenegotiate = (row: any) => {
    setRow(row);
    setShowNegotiateModal(true);
  };

  const onCardClickHandler = (product: any) => {
    let { auctionType, auctionStartDate, auctionId, perId, productCategory } = product;
    if (auctionType === "Annual") {

      let isMypage: boolean = true;
      let auctionDate = auctionStartDate.split(" ", 2);
      if (productCategory === "Jewellery") {
        window.open(
          `/${languageCode}/365products/details/${auctionId}/${jwljoinedAuctionUserId}/${perId}/${auctionDate[0]}/${isMypage}`,
          "detailsWindowName",
          "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
        );
      } else {
        window.open(
          `/${languageCode}/365products/details/${auctionId}/${joinedAuctionUserId}/${perId}/${auctionDate[0]}/${isMypage}`,
          "detailsWindowName",
          "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
        );
      }
    } else {
      window.open(
        `/${languageCode}/my-page/diamonds/details/${product.auctionId}/${product.perId}/${product.auctionStartDate}`,
        "detailsWindowName",
        "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
      );
    }
  };
  const onWatchListBtnHandler = () => {
    // onWatchListBtnHandler
  };

  const onSortHandler = (selectedOption: any) => {
    if (selectedOption[0] !== undefined) {
      let { field, sort } = selectedOption[0];
      let sortPayload: any = {
        property: field,
        direction: sort.toUpperCase(),
      };
      setSort(sortPayload);
      callApi(0);
    }
  };

  const newrenderFiltered = (x: any) => {
    return filterList.length === 0
      ? true
      : filterList.includes(x.colorFilter);
  };

  const renderFiltered = (x: any) => {
    return filterList.length === 0
      ? true
      : filterList.includes(x.colorFilter);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== "") {
      setFilterList(event.target.value as string);
    } else {
      setFilterList("");
    }
    setPage(0);
  };

  return (
    <div>
      {renderSellandBuyHeader()}
      {renderSearchBar()}
      <StyledMypageGrid style={{ padding: "15px 10px", alignItems: "center" }}>
        <Grid item={true} xs={12}>
          {isGridView || isMobile ? (
            <MyPageProductsGrid
              gridData={{
                list: product.filter((x: any) =>
                  newrenderFiltered(x)
                ),
                totalElements: filterList === "" ? totalElements : product.filter((x: any) =>
                  newrenderFiltered(x)
                ).length,
              }}
              sortingProps={{ showCarat: true }}
              onCardClickHandler={onCardClickHandler}
              fetchAuctionProducts={() => {
                setIsGetAuctionProducts(true);
              }}
              onWatch={onWatchListBtnHandler}
              onSort={onSortHandler}
              itemperpage={selectItemPerPageValue}
              setPage={callApi}
              page={page}
              exportIds={exportIds}
              selectItemInGridView={selectItemInGridView}
              handleWatchlist={handleWatchlist}
              isPageChange={isPageChange}
              isSellPage={false}
            />
          ) : (
            <MypageTable
              data={product.filter((x: any) =>
                newrenderFiltered(x)
              )}
              totalElements={filterList === "" ? totalElements : product.filter((x: any) =>
                newrenderFiltered(x)
              ).length}
              changePageNum={callApi}
              setPage={setPage}
              isLoading={isLoading}
              page={page}
              isSellPage={false}
              handlePrebid={handlePrebid}
              handleWatchlist={handleWatchlist}
              handleNegotiate={handlenegotiate}
              exportIds={setexportIds}
              itemperpage={selectItemPerPageValue}
              onSort={onSortHandler}
              onDetailsPageAction={onCardClickHandler}
              jwlAuctionerId={jwljoinedAuctionUserId}
              diamondAuctionerId={joinedAuctionUserId}
            />
          )}
        </Grid>
      </StyledMypageGrid>
      {showPreBidModal && renderPrebidPopup()}
      {showNegotiateModal && renderNegotiateModal()}
      {openToast && (
        <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
      )}
      {readOnly && readOnlyUserModal()}
    </div>
  );
};

export default BuyMypage;
