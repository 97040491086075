import React from "react";
import { StyledMuiDataTableLayoutGrid, StyledTypography } from "../MypageStyle";
import { useTranslation } from "react-i18next";
import { convertProductPrice } from "../../pendingProductsTab/TableProps";
import { DataGrid, GridColDef, GridOverlay } from "@material-ui/data-grid";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@material-ui/core";
import { StyledBtnContainer } from "../../365Auction/component/DiamondProductStyle";
import Chip from "@mui/material/Chip";
import { StyledMuiDataTableBtnContainer } from "../../pendingProductsTab/PendingProductsStyle";
import { useHistory } from "react-router";
import { getRapNet } from "../../products/preview/TenderGenericFunc";
import { getSplitDate, getSplitedTime } from "../../../utils/DateTimeUtils";
import EllipsisText from "../../../common/elements/EllipsisText";
import { getAnnualRapnetPercentage } from "../MypageApiService";
import moment from "moment";
import ParcelProductDescription from "../../shared/ParcelProductDescription";
import { ParcelType } from "../../../enums/productEnum";

const faHeartProps = faHeart as IconProp;
interface Iprops {
  data?: any;
  totalElements: number;
  changePageNum: (page: number) => void;
  isLoading: boolean;
  page: number;
  isSellPage: boolean;
  handlePrebid: (id: string, data: any, isPrebid: boolean) => void;
  handleNegotiate: (row: any) => void;
  handleWatchlist: (
    auctionId: string,
    productId: string,
    isWatch: boolean
  ) => void;
  exportIds: (ids: any) => void;
  setPage: (page: number) => void;
  itemperpage: number;
  onSort: (payload: any) => void;
  onDetailsPageAction: (item: any) => void;
  jwlAuctionerId: string;
  diamondAuctionerId: string;
}
let prevSortState: any; // for quick fix

function MypageTable(props: Iprops) {
  let {
    data,
    changePageNum,
    totalElements,
    isLoading,
    page,
    isSellPage,
    handlePrebid,
    handleWatchlist,
    exportIds,
    handleNegotiate,
    setPage,
    itemperpage,
    onDetailsPageAction
  } = props;
  let { t } = useTranslation();
  let history = useHistory();
  let timezone: any = localStorage.getItem("timezoneName");
  const columns: GridColDef[] = [
    {
      field: "serialNo",
      headerName: "S.no.",
      sortable: true,
      editable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              onDetailsPageAction(params.row)
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: "auctionName",
      headerName: "Auction Name / Date",
      width: 200,
      flex: 1,
      sortable: true,
      editable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        var parcelType: any = params.row.parcelType;
        return (
          <Typography style={{ whiteSpace: "break-spaces", fontSize: "1em" }}>
            <span
              onClick={() => {
                onDetailsPageAction(params.row)
              }}
            >
              {params.value} ({parcelType != undefined || parcelType != null ? parcelType : ''}) {" "}
              {getDate(params.row.productStartDate)}
            </span>
          </Typography>
        );
      },
    },
    {
      field: "description",
      headerName: `${t("common:newCommanTrans:productdescription")}`,
      width: 260,
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Typography style={{ whiteSpace: "break-spaces", fontSize: "1em" }}>
          {params.row?.productSearchDescription !== undefined &&
            params.row?.productSearchDescription !== null ? (
            <>{params.row?.parcelType === ParcelType.PARCEL ?
              <ParcelProductDescription
                data={params.row}
                truncateLength={100}
                isTendor={false}
              /> :
              <EllipsisText
                text={params.row?.productSearchDescription}
                length={100}
                tail={"..."}
              />

            }


            </>
          ) : (
            <></>
          )}
        </Typography>
      ),
    },
    {
      field: "caratMinimumPrice",
      headerName: "Price",
      width: 350,
      sortable: true,
      editable: false,
      filterable: false,
      headerAlign: "center",
      renderCell: (params: any) => (
        <>
          {isSellPage ? (
            <> {renderSellPriceSection(params.row)}</>
          ) : (
            <> {renderbuyPriceSection(params.row)}</>
          )}
        </>
      ),
    },
    {
      field: "rapOff",
      headerName: "Rap Off",
      width: 300,
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
      headerAlign: "center",
      renderCell: (params: any) => (
        <div>
          <StyledTypography color="textPrimary">
            <span>Starting Rap:</span> {params.row.auctionType === "Annual" ? getAnnualRapnetPercentage(params.row, params.row.caratMinimumPrice) : getRapNet(params.row.rapnetPercentage)}{" "}
          </StyledTypography>
          <StyledTypography color="textPrimary">
            <span>Bid Rap:</span>
            {params.row.auctionType === "Annual" ? getAnnualRapnetPercentage(params.row, Math.max(params.row.preBidPrice, params.row.highestBidPrice)) : getRapnetPercentage(params.row, params.row.currentBidPrice)}
          </StyledTypography>
          <StyledTypography color="textPrimary">
            <span>Live Highest Rap:</span>
            {params.row.auctionType === "Annual" ? getAnnualRapnetPercentage(params.row, params.row.currentBidPrice) : "-"}
          </StyledTypography>
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Status",
      sortable: false,
      width: 250,
      editable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <>
          {isSellPage ? (
            <Grid
              container
              spacing={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {renderStateAction(params.row.state, params.row)}
            </Grid>
          ) : (
            <Grid
              container
              spacing={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: 'row'
              }}
            >
              {params.row.auctionType === "Annual" ? renderAnnualBuyStateAction(params.row) : renderBuyStateAction(params.row.state, params.row)}
            </Grid>
          )}
        </>
      ),
    },
  ];



  const getDate = (productDate: any) => {
    let previewTime = moment.utc(productDate);
    if (previewTime !== null || previewTime !== undefined) {
      let result = moment.tz(previewTime, timezone);
      let resultFormatter = result.format();
      if (resultFormatter !== "Invalid date") {
        let splitDateTime = resultFormatter.split("T", 2);
        return splitDateTime[0];
      }
    }
  };

  const getRapnetPercentage = (rowData: any, currentBidPrice: any) => {
    let productPricePerCaratUSD: any;
    let rapoffValue: any;
    if (
      rowData.rapnetPercentage === undefined ||
      rowData.rapnetPercentage === null ||
      rowData.rapnetPercentage === 0 ||
      rowData.rapnetPercentage === "0"
    ) {
      return "-";
    } else {
      if (rowData.parcelType !== "Jewellery") {
        if (rowData.auctionState === "NotStarted") {
          if (rowData.preBidPrice === undefined || rowData.preBidPrice === 0) {
            return "-";
          }
          if (rowData.currency.code === "JPY") {
            productPricePerCaratUSD =
              rowData.preBidPrice * rowData.exchangeRates[0].rate;
          } else {
            productPricePerCaratUSD = rowData.preBidPrice;
          }
          rapoffValue = (
            (1 - productPricePerCaratUSD / rowData.rapnetPrice) *
            -100.0
          ).toFixed(2);

          return rapoffValue !== "NaN" ? rapoffValue : "-";
        } else {
          if (rowData.state !== "Available") {
            if (rowData.currency.code === "JPY") {
              productPricePerCaratUSD =
                rowData.currentBidPrice * rowData.exchangeRates[0].rate;
            } else {
              productPricePerCaratUSD = rowData.currentBidPrice;
            }
            rapoffValue = (
              (1 - productPricePerCaratUSD / rowData.rapnetPrice) *
              -100.0
            ).toFixed(2);
            return rapoffValue !== "NaN" ? rapoffValue : "-";
          } else {
            return "-";
          }
        }
      } else {
        return "-";
      }
    }
  };


  const renderStateAction = (state: any, rowData: any) => {
    let stateUi;
    switch (state) {
      case "Sold":
        stateUi = (
          <span
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              if (rowData.auctionType === "Annual") {
                history.push(`/invoice/365DaysAuctionsinvoice`);
              } else {
                history.push(`/invoice`);
              }
            }}
          >
            INVOICE
          </span>
        );
        break;
      case "Hold":
        stateUi = <span>{state}</span>;
        break;
      case "Unsold":
        stateUi = <span>{state}</span>;
        break;

      default:
        stateUi = (
          <StyledTypography color="textPrimary">
            <span style={{ whiteSpace: "break-spaces" }}>
              {rowData.auctionType === "Annual" ? rowData.annualProductState === "NotStarted" ? `Auction scheduled on ${getUserAuctionTime(rowData.productStartDate)}` : "Product is Live." : getUserAuctionTime(rowData.auctionStartDate)}
            </span>
          </StyledTypography>
        );
        break;
    }
    return stateUi;
  };

  const renderBuyStateAction = (state: any, rowData: any) => {
    if (state === "Sold") {
      return <>{state}</>;
    } else if (state === "Hold") {
      return (
        <span>
          {rowData?.negotiable ? (
            <StyledMuiDataTableBtnContainer>
              {rowData?.negotiated ? (
                <Chip
                  variant="outlined"
                  disabled={true}
                  size="small"
                  color="primary"
                  style={{ cursor: "pointer", color: "#2660ad" }}
                  label={t("common:pendingProduct:button:negotiate")}
                />
              ) : (
                <Chip
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleNegotiate(rowData);
                  }}
                  style={{ cursor: "pointer", color: "#2660ad" }}
                  label={t("common:pendingProduct:button:negotiate")}
                />
              )}
            </StyledMuiDataTableBtnContainer>
          ) : (
            <span>Unsold</span>
          )}
        </span>
      );
    } else if (state === "Unsold") {
      return <>{state}</>;
    } else {
      if (rowData.watched && rowData.preBidPrice === 0) {
        return (
          <>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <FontAwesomeIcon
                icon={faHeartProps}
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  handleWatchlist(rowData.auctionId, rowData.perId, false);
                }}
              />
            </Grid>
            <Grid>
              <StyledBtnContainer>
                {rowData?.auctionType === "Live" && (
                  <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    label="Prebid"
                    style={{ cursor: "pointer", color: "#2660ad" }}
                    onClick={() => {
                      handlePrebid(rowData.perId, rowData, true);
                    }}
                  />
                )}
              </StyledBtnContainer>
            </Grid>
          </>
        );
      } else if (rowData.preBidPrice !== 0 && rowData.watched) {
        return (
          <>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <FontAwesomeIcon
                icon={faHeartProps}
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  handleWatchlist(rowData.auctionId, rowData.perId, false);
                }}
              />
            </Grid>
            <Grid item>
              <StyledBtnContainer>
                {rowData?.auctionType === "Live" && (
                  <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    label="Cancel Prebid"
                    style={{ cursor: "pointer", color: "#2660ad" }}
                    onClick={() => {
                      handlePrebid(rowData.perId, rowData, false);
                    }}
                  />
                )}
              </StyledBtnContainer>
            </Grid>
          </>
        );
      } else if (rowData.preBidPrice !== 0 && rowData.watched === false) {
        return (
          <Grid item>
            <StyledBtnContainer>
              {rowData?.auctionType === "Live" && (
                <Chip
                  variant="outlined"
                  size="small"
                  color="primary"
                  label="Cancel Prebid"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handlePrebid(rowData.perId, rowData, false);
                  }}
                />
              )}
            </StyledBtnContainer>
          </Grid>
        );
      } else if (rowData.preBidPrice !== 0 && rowData.watched === undefined) {
        return (
          <>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <FontAwesomeIcon
                icon={faHeartProps}
                style={{ color: "grey", cursor: "pointer" }}
                onClick={() => {
                  handleWatchlist(rowData.auctionId, rowData.perId, true);
                }}
              />
            </Grid>
            <Grid item>
              <StyledBtnContainer>
                {rowData?.auctionType === "Live" && (
                  <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    label="Cancel Prebid"
                    style={{ cursor: "pointer", color: "#2660ad" }}
                    onClick={() => {
                      handlePrebid(rowData.perId, rowData, false);
                    }}
                  />
                )}
              </StyledBtnContainer>
            </Grid>
          </>
        );
      } else if (rowData.preBidPrice === 0 && rowData.watched === false) {
        return (
          <Grid item>
            <StyledBtnContainer>
              {rowData?.auctionType === "Live" && (
                <Chip
                  variant="outlined"
                  size="small"
                  color="primary"
                  label="Prebid"
                  style={{ cursor: "pointer", color: "#2660ad" }}
                  onClick={() => {
                    handlePrebid(rowData.perId, rowData, true);
                  }}
                />
              )}
            </StyledBtnContainer>
          </Grid>
        );
      }
    }
  };

  const renderAnnualBuyStateAction = (rowData: any) => {
    let { state, rowColor, watched, preBidPrice } = rowData;

    if (state === "Available") {
      let valueShow;
      switch (rowColor) {
        case "#FBE4D5":
          if (watched) {
            valueShow =
              <>
                <Grid style={{
                  gap: '5px', display: 'flex',
                  alignItems: 'center'
                }}>
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => {
                      handleWatchlist(rowData.auctionId, rowData.perId, false);
                    }}
                  />
                  <span style={{ color: 'green' }}>Winner</span>
                </Grid>
              </>
          } else {
            valueShow = <span style={{ color: 'green' }}>Winner</span>
          }
          break;
        case "#FFEB9C":
          if (watched) {
            valueShow = <>
              <Grid style={{
                gap: '5px', display: 'flex',
                alignItems: 'center'
              }}>
                <FontAwesomeIcon
                  icon={faHeartProps}
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => {
                    handleWatchlist(rowData.auctionId, rowData.perId, false);
                  }}
                />
                <span>Almost Sold</span>
              </Grid>
            </>
          } else {
            valueShow = <span>Almost Sold</span>
          }
          break;
        case "#FFFFFF":
          if (watched) {
            valueShow = <>
              <Grid style={{
                gap: '5px', display: 'flex',
                alignItems: 'center'
              }}>
                <FontAwesomeIcon
                  icon={faHeartProps}
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => {
                    handleWatchlist(rowData.auctionId, rowData.perId, false);
                  }}
                />
                {preBidPrice !== 0 && <span style={{ color: 'red' }}>Bid Surpassed</span>}
              </Grid>
            </>
          } else {
            valueShow = <span style={{ color: 'red' }}>{preBidPrice !== 0 && <span style={{ color: 'red' }}>Bid Surpassed</span>}</span>
          }
          break;
        default:
          if (watched) {
            valueShow = <>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <FontAwesomeIcon
                  icon={faHeartProps}
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => {
                    handleWatchlist(rowData.auctionId, rowData.perId, false);
                  }}
                />
              </Grid>
            </>
          }
          break;
      }
      return valueShow;
    } else if (state === "Hold") {
      return (
        <span>
          {rowData?.negotiable ? (
            <StyledMuiDataTableBtnContainer>
              {rowData?.negotiated ? (
                <Chip
                  variant="outlined"
                  disabled={true}
                  size="small"
                  color="primary"
                  style={{ cursor: "pointer", color: "#2660ad" }}
                  label={t("common:pendingProduct:button:negotiate")}
                />
              ) : (
                <Chip
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleNegotiate(rowData);
                  }}
                  style={{ cursor: "pointer", color: "#2660ad" }}
                  label={t("common:pendingProduct:button:negotiate")}
                />
              )}
            </StyledMuiDataTableBtnContainer>
          ) : (
            <span>Unsold</span>
          )}
        </span>
      );
    } else {
      return <>{state}</>;
    }
  };

  const renderSellPriceSection = (row: any) => {
    return (
      <div style={{ justifyContent: "center" }}>
        <StyledTypography>
          <span>A. Starting Price:</span>
          {convertProductPrice(row, row.caratMinimumPrice)}
        </StyledTypography>
        <StyledTypography>
          <span>B. Reserve Price:</span>
          {convertProductPrice(row, row.caratSellingPrice)}
        </StyledTypography>
        <StyledTypography>
          <span>C. Bid Price:</span>
          <>
            {row.state !== "Available" ? (
              <>{convertProductPrice(row, Math.max(row.preBidPrice, row.highestBidPrice))}</>
            ) : (
              <>{row.auctionType === "Annual" ? convertProductPrice(row, Math.max(row.preBidPrice, row.highestBidPrice)) : `-`}</>
            )}
          </>
        </StyledTypography>
        <StyledTypography>
          <span>D. Live highest Price:</span>{" "}
          <>{row.auctionType === "Annual" ? convertProductPrice(row, row.currentBidPrice) : `-`}</>
        </StyledTypography>
        <StyledTypography>
          <span>E. Winning Price:</span>{" "}
          {row.state === "Sold" || row.state === "Unsold" ? (
            <> {convertProductPrice(row, row.currentBidPrice)}</>
          ) : (
            <>{row.auctionType === "Annual" ? row.annualProductState === "Ended" ? convertProductPrice(row, row.currentBidPrice) : "-" : `-`}</>
          )}
        </StyledTypography>

      </div>
    );
  };

  const renderbuyPriceSection = (row: any) => {
    return (
      <div>
        <StyledTypography>
          <span>A. Starting Price:</span>
          {convertProductPrice(row, row.caratMinimumPrice)}
        </StyledTypography>
        <StyledTypography>
          <span>B. Reserve Price:</span>
          {row.state === "Hold" ? (
            <>{convertProductPrice(row, row.caratSellingPrice)}</>
          ) : (
            <>-</>
          )}
        </StyledTypography>
        <StyledTypography>
          <span>C. Bid Price:</span>
          {row.state === "Available" ? (
            <>{convertProductPrice(row, Math.max(row.preBidPrice, row.highestBidPrice))}  </>
          ) : (
            <>{row.auctionType === "Annual" ? convertProductPrice(row, Math.max(row.preBidPrice, row.highestBidPrice)) : prebidRender(row)}</>
          )}
        </StyledTypography>
        <StyledTypography>
          <span>D. Live highest Price:</span>{" "}
          <>{row.auctionType === "Annual" ? convertProductPrice(row, row.currentBidPrice) : `-`}</>
        </StyledTypography>
        <StyledTypography>
          <span>E. Winning Price:</span>{" "}
          {row.state === "Sold" || row.state === "Unsold" ? (
            <> {convertProductPrice(row, row.currentBidPrice)}</>
          ) : (
            <>-</>
          )}
        </StyledTypography>
      </div>
    );
  };

  // prebid logic
  const prebidRender = (row: any) => {
    if (row.preBidPrice === 0) {
      return convertProductPrice(row, row.highestBidPrice);
    } else {
      if (row.preBidPrice < row.highestBidPrice) {
        return convertProductPrice(row, row.highestBidPrice);
      } else {
        if (row.preBidPrice !== undefined) {
          return convertProductPrice(row, row.preBidPrice);
        } else {
          return convertProductPrice(row, row.highestBidPrice);
        }
      }
    }
  };

  const getUserAuctionTime = (date: any) => {
    let timezone: any = localStorage.getItem("timezoneName");
    if (timezone !== null) {
      return ` ${getSplitDate(date, timezone)} ${getSplitedTime(
        date,
        timezone
      )}`;
    }
  };

  const getTableBodyHeight = () => {
    let heightOfInnerTable = window.innerHeight - 270;
    return `${heightOfInnerTable}px`;
  };

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <div>No Product Found.</div>
      </GridOverlay>
    );
  }

  return (
    <StyledMuiDataTableLayoutGrid
      style={{ height: getTableBodyHeight(), width: "100%" }}
    >
      <DataGrid
        rows={data}
        page={page}
        rowCount={totalElements}
        columns={columns}
        paginationMode="server"
        pageSize={itemperpage}
        rowsPerPageOptions={[]}
        pagination
        rowHeight={120}
        loading={isLoading}
        disableColumnMenu
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        getRowId={(row) => row.perId}
        checkboxSelection
        onSelectionModelChange={(itm: any) => {
          exportIds(itm);
        }}
        onPageChange={(page: number) => {
          setPage(page);
          changePageNum(page);
        }}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </StyledMuiDataTableLayoutGrid>
  );
}

export default MypageTable;
