import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";

const StyledArrowWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 30%;
  width: 30px;
  cursor: pointer;
`;

const StyledArrows = styled("img")``;

const StyledWrapperGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  padding: 10px;
`;

const StyledGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  height: 100%;
  padding: 0em 1em;
  display: bock;
  justify-content: center;
`;

const StyledCorouselWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  position: relative;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    .carousel .slide iframe {
      width: calc(100% - -120px) !important;
    }
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    width: 410px !important;
    .carousel .slide iframe {
      width: calc(100% - -100px) !important;
    }
  }
  
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    width: 450px;
  }

  @media (min-width: 700px) and (max-width: 959.95px) {
    .carousel .slide iframe {
      width: 100% !important;
    }
  }
  .carousel.carousel-slider {
    overflow: visible;
    position: relative;
  }
  .carousel .slider-wrapper {
    overflow: visible;
  }
  .carousel .slide {
    visibility: hidden;
    background: ${(props) => props.theme.colors.base.white};
  }
  .carousel .slide.selected {
    visibility: visible;
  }
  .fWstHB {
    padding-top: 0px;
  }
`;

const StyledProductImage = styled("img")`
  width: 100%;
`;

const StyledLargeText = styled.span`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
`;

const StyledLargeTxtImg = styled("img")`
  width: 25px !important;
  height: 25px !important;
`;

const StyledLargeTxtCon = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
`;

const StyledNotAvailableImage = styled.div`
  justify-content: center;
`;

const StyledFrame = styled.iframe`
  width: 100%;
  height: 500px;
  position: relative;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 290px;
  }

  ${(props) => props.theme.breakpoints.only("md")} {
    height: 470px;
    margin: 0 20px 20px !important;
  }

  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    height: 390px;
  }
  @media (min-width: 700px) and (max-width: 1020px) {
    height: 360px;
    margin: 0 60px !important;
  }

  @media (width: 768px) {
    height: 290px;
  }
  @media (width: 820px) {
    height: 290px;
    margin: 0 40px !important;
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;


const StyledCorouselWrapper365 = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  position: relative;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    .carousel .slide iframe {
      width: calc(100% - -120px) !important;
    }
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    width: 410px !important;
    .carousel .slide iframe {
      width: calc(100% - -100px) !important;
    }
  }

  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    width: 450px;
    .carousel .slide iframe {
      width: calc(100% - 120px) !important;
    }
  }

  @media (min-width: 700px) and (max-width: 959.95px) {
    .carousel .slide iframe {
      width: 100% !important;
    }
  }
  .carousel.carousel-slider {
    overflow: visible;
    position: relative;
  }
  .carousel .slider-wrapper {
    overflow: visible;
  }
  .carousel .slide {
    visibility: hidden;
    background: ${(props) => props.theme.colors.base.white};
  }
  .carousel .slide.selected {
    visibility: visible;
  }
  .fWstHB {
    padding-top: 0px;
  }
`;


export {
  StyledArrowWrapper,
  StyledArrows,
  StyledWrapperGrid,
  StyledGrid,
  StyledCorouselWrapper,
  StyledProductImage,
  StyledLargeText,
  StyledLargeTxtImg,
  StyledLargeTxtCon,
  StyledNotAvailableImage,
  StyledFrame,
  StyledModal,
  StyledCorouselWrapper365,
};
