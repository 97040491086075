import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { faTag, faClock } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import {
  StyledProductImageContainer,
  StyledProductImage,
  StyledProductTime,
  StyledBidButton,
  StyledSubheading,
  StyledProductHeading,
  StyledFaTag,
  StyledPaddingContainer,
  StyledPrice,
  StyledInfoContainer,
  StyledStartingPrice,
  StyledBox,
  StyledShadowContainer,
  StyledRapnetContainer,
  StyledArrowButton,
} from "./StyledComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getImageSource, toggleLoginPopup } from "../Utils";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useDialog } from "../../../common/elements/Dialog/Dialog";
import { SignIn } from "../Header/SignIn";
import Colors from "../../../common/materials/colors";
import Cross from "../Static/Icons/Cross";
import Button from "../../../common/elements/Button";
import { LandingPagePlayFairFont } from "../StyledComponents";
import { setParticipateProductId } from "../../../redux/slice/commanPagination/commanPaginationSlice";
import ArrowButton from "../Static/Icons/ArrowButton";
import { ParcelType } from "../../../enums/productEnum";
import { MobileSignIn } from "../../zipang/Header/ZipangHeader";

export interface IProductCardProps {
  exchangeRates: any;
  auctionName: string;
  productStartDate: string;
  productEndDate: string;
  annualProductState: string;
  carat: string;
  color: string;
  clarity: string;
  cutGrade: string;
  flour: string;
  polish: string;
  symmetryAbbreviation: string;
  shape: null | string;
  sieve: null | string;
  code: string;
  parcelType: string;
  medias: Array<{ mediaType: string; presignedUrl: string }>;
  buttonText: string;
  caratMinimumPrice: number;
  startingPrice: number;
  rapnetPercentage: number;
  rapnetPrice: number;
  currentBidPrice: number;
  time: string;
  jewelleryDetails: {
    jewelleryCondition: string;
    jewelleryDescription: string;
    jewelleryType: string;
  };
  currency: {
    code: string;
    symbol: string;
  };
  redacted?: boolean;
  auctionType: string;
  buyItNow: number;
}

const faTagProps = faTag as IconProp;
const faClockProps = faClock as IconProp;

const getProductDescription = (
  t: (arg0: string) => any,
  carat: string,
  color: string,
  clarity: string,
  cutGrade: string,
  flour: string,
  polish: string,
  symmetryAbbreviation: string,
  shape: null | string,
  sieve: null | string
) => {
  const CARAT: any =
    carat === undefined ? "" : `${carat} ${t("common:preview:ct")}`;
  const COLOR: any = color === undefined ? "" : color;
  const CLARITY: any = clarity === undefined ? "" : clarity;
  const CUTGRADE: any = cutGrade === undefined ? "" : cutGrade;
  const FLOUR: any = flour === undefined ? "" : flour;
  const POLISH: any =
    polish === undefined ? "" : `${t("common:preview:pol")}=${polish}`;
  const SYMMETRY_ABBREVATION: any =
    symmetryAbbreviation === undefined
      ? ""
      : `${t("common:preview:sym")}=${symmetryAbbreviation}`;
  const PRODUCT_SHAPE: any = shape === undefined || shape === null ? "" : shape;
  const PRODUCT_SIEVE: any = sieve === undefined || sieve === null ? "" : sieve;
  return [
    CARAT,
    COLOR,
    PRODUCT_SHAPE,
    PRODUCT_SIEVE,
    CLARITY,
    CUTGRADE,
    FLOUR,
    POLISH,
    SYMMETRY_ABBREVATION,
  ].reduce((acc, type) => (type ? acc + `${type} ` : acc), "");
};

const getJewellaryDesc = (details: {
  jewelleryCondition: string;
  jewelleryDescription: string;
  jewelleryType: string;
}) => {
  return `${details.jewelleryDescription} ${details.jewelleryCondition} ${details.jewelleryType}`;
};

const useStyles = makeStyles({
  container: {
    padding: "2em 1em",
    background: Colors.grey1,
    position: "relative",
  },
  cross: {
    position: "absolute",
    right: "1em",
    top: "1em",
    margin: 0,
  },
});

const getAmountToBeShown = (
  caratMinimumPrice: number,
  currency: any,
  exchangeRates: any
) => {
  let caratMinPrice = new Intl.NumberFormat("us-en").format(caratMinimumPrice);
  let amountToBeShown = "";
  if (
    caratMinimumPrice === null ||
    caratMinPrice === "NaN" ||
    caratMinPrice === "0"
  ) {
    caratMinPrice = "-";
  } else {
    let conversionPriceS = new Intl.NumberFormat("us-en").format(
      Math.round(caratMinimumPrice * exchangeRates[0]["rate"])
    );

    caratMinPrice = `${currency["symbol"]}${caratMinPrice}`;

    if (currency["code"] === "USD") {
      amountToBeShown = `¥${conversionPriceS} (${caratMinPrice})`;
    } else {
      amountToBeShown = `${caratMinPrice} ($${conversionPriceS})`;
    }
    return amountToBeShown;
  }
};

const ProductCard = ({
  fixedHeightCards = false,
  exchangeRates,
  currentBidPrice,
  auctionName,
  parcelType,
  medias,
  code,
  buttonText,
  caratMinimumPrice,
  startingPrice,
  rapnetPercentage,
  rapnetPrice,
  time,
  currency,
  carat,
  color,
  clarity,
  cutGrade,
  flour,
  polish,
  sieve,
  symmetryAbbreviation,
  shape,
  jewelleryDetails,
  hideBidButton = false,
  redacted = false,
  isZipang
}: IProductCardProps & {
  fixedHeightCards?: boolean;
  hideBidButton?: boolean;
  isZipang: boolean;
}) => {

  const getMediasForCarosuel = () => {
    const mediaslist: any = medias;
    for (let i = 0; i < mediaslist.length; i++) {
      let mediaItem = mediaslist[i];
      if (mediaItem?.mediaType === "PHOTO") {
        if (mediaItem?.primary === true) {
          mediaslist.splice(i, 1);
          mediaslist.unshift(mediaItem);
          break;
        }
      }
    }
    return mediaslist;
  };
  const img = getImageSource(getMediasForCarosuel());
  // console.log(img);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const numFor = Intl.NumberFormat("en-US");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isParcelTypeDiamond = () => {
    return parcelType === ParcelType.DIAMOND;
  };
  const isParcelTypeJewellery = () => {
    return parcelType === ParcelType.JEWELLERY;
  };
  const [createDialog, closeDiage] = useDialog();
  const classes = useStyles();

  const [mouseOver, setMouseOver] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const handleMobileSignIn = () => {
    createDialog({
      children: (
        <div className={classes.container}>
          <Button
            type=""
            onClick={closeDiage}
            className={classes.cross}
            noMargin
          >
            <Cross filled />
          </Button>
          <p>
            <Typography variant="h4" align="center">
              <LandingPagePlayFairFont>
                {t("common:signIntoYourAcc")}
              </LandingPagePlayFairFont>
            </Typography>
          </p>
          <SignIn handleClose={closeDiage} />
        </div>
      ),
      fullWidth: true,
      open: true,
    });
  };

  const handleBidClick = () => {
    if (hideBidButton) return;
    dispatch(setParticipateProductId({ id: code }));
    if (isMobile) {
      if (isZipang) {
        setShowSignIn(true);
      } else {
        handleMobileSignIn();
      }
    } else {
      toggleLoginPopup();
    }
  };
  // const time = moment()

  const amountToBeShown = getAmountToBeShown(
    currentBidPrice,
    currency,
    exchangeRates
  );
  const carratMinToBeShown = getAmountToBeShown(
    caratMinimumPrice,
    currency,
    exchangeRates
  );

  const descriptionText =
    parcelType === "Diamond" || parcelType === "Parcel"
      ? getProductDescription(
        t,
        carat,
        color,
        clarity,
        cutGrade,
        flour,
        polish,
        symmetryAbbreviation,
        shape,
        sieve
      )
      : parcelType === "Jewellery"
        ? getJewellaryDesc(jewelleryDetails)
        : null;

  const handleMouseOver = () => {
    setMouseOver(!mouseOver);
  };
  const handleSignUpModal = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    const hiddenButton = document.getElementById("hiddenButton");
    if (!hiddenButton) return;
    hiddenButton.click();
  };

  const getRapnetPercentage = () => {
    if (!rapnetPercentage) {
      return "-";
    }
    if (rapnetPercentage == 0 || rapnetPercentage == 0.0) {
      return "-";
    }
    try {
      let amount: number;
      const rate = exchangeRates?.[0]?.["rate"] ?? 1;
      if (currency.code === "JPY") {
        amount = (currentBidPrice || caratMinimumPrice) * rate;
      } else {
        amount = currentBidPrice || caratMinimumPrice;
      }

      const price = 1 - amount / rapnetPrice || 0;
      const firstTwoNumbers = price * -100;
      const fixed = firstTwoNumbers.toFixed(2);
      return fixed;
    } catch (e) {
      return "-";
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOver}
      style={{ width: isMobile ? "80vw" : "auto", }}
    >
      <StyledShadowContainer onClick={handleBidClick}>
        <StyledProductImageContainer>
          {redacted ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={"100%"}
              width={"100%"}
            />
          ) : (
            <>
              {img === undefined ? (
                <StyledProductImage
                  loading="lazy"
                  src={`/images/${isParcelTypeDiamond() || isParcelTypeJewellery()
                    ? "default-diamond.png"
                    : "default-parcel.png"
                    }`}
                ></StyledProductImage>
              ) : (
                <StyledProductImage
                  loading="lazy"
                  src={img?.presignedUrl}
                ></StyledProductImage>
              )}
            </>
          )}
          {time && (
            <StyledProductTime>
              <FontAwesomeIcon icon={faClockProps} />
              &nbsp;
              {time}
            </StyledProductTime>
          )}
          {hideBidButton ? (
            (mouseOver || isMobile) && (
              <StyledArrowButton>
                <ArrowButton onClick={handleSignUpModal} />
              </StyledArrowButton>
            )
          ) : (
            <StyledBidButton
              onClick={handleBidClick}
              type={isZipang ? "contained-dark-Red" : "contained-dark"}
              noMargin
            >
              {t("common:bid")}
            </StyledBidButton>
          )}
        </StyledProductImageContainer>
        <StyledPaddingContainer>
          <StyledSubheading>
            {redacted ? (
              <Skeleton variant="rectangular" animation="wave" />
            ) : (
              code
            )}
          </StyledSubheading>
          <StyledProductHeading>
            {redacted ? (
              <Skeleton variant="rectangular" animation="wave" />
            ) : (
              <strong title={descriptionText}>{descriptionText}</strong>
            )}
          </StyledProductHeading>
          <StyledBox
            display="flex"
            justifyContent="space-between"
            alignContent="space-between"
          >
            <StyledInfoContainer>
              <StyledBox
                display="flex"
                justifyContent="space-between"
                alignContent="center"
              >
                <Box display="flex">
                  {redacted ? (
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={20}
                    ></Skeleton>
                  ) : (
                    <StyledFaTag icon={faTagProps} style={{ color: isZipang ? "#d92b3c" : "#4f96e2" }} />
                  )}
                  <div>
                    {redacted ? (
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={20}
                      ></Skeleton>
                    ) : (
                      (caratMinimumPrice || currentBidPrice) && (
                        <StyledPrice fixedHeight={fixedHeightCards}>
                          {currentBidPrice
                            ? amountToBeShown
                            : carratMinToBeShown}
                        </StyledPrice>
                      )
                    )}
                    {!redacted && caratMinimumPrice && (
                      <StyledStartingPrice>
                        <span>
                          &nbsp;
                          <strong>
                            {t("common:preview:productsList:startingPrice")}
                          </strong>
                          &nbsp;
                          <span>
                            {currency.symbol}
                            {numFor.format(caratMinimumPrice)}
                          </span>
                        </span>
                      </StyledStartingPrice>
                    )}
                  </div>
                </Box>
                {!redacted && rapnetPercentage !== undefined && (
                  <StyledRapnetContainer>
                    <StyledStartingPrice>
                      <strong>{t("common:preview:productsList:ro")}</strong>
                    </StyledStartingPrice>
                    {getRapnetPercentage()}
                  </StyledRapnetContainer>
                )}
                {!rapnetPercentage && redacted && (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={40}
                  ></Skeleton>
                )}
              </StyledBox>
            </StyledInfoContainer>
          </StyledBox>
        </StyledPaddingContainer>
      </StyledShadowContainer>
      <MobileSignIn show={showSignIn} onClose={() => setShowSignIn(false)} />
    </Grid>
  );
};

export default React.memo(ProductCard);
