/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import BaseCardLayout from "./BaseCardLayout";
import ProductDescription from "../../../../shared/ProductDescription";
import EllipsisText from "../../../../../common/elements/EllipsisText";
import { RootState } from "../../../../../redux/reducer";
import { ParcelType, MediaType } from "../../../../../enums/productEnum";
import { getNumberFormat } from "../../../../../services/NumberFormatService";
import { selectPreviewSelectedAuction } from "../../../../../redux/slice/auction/auctionSlice";
import { Checkbox, useMediaQuery } from "@material-ui/core";
import AddorNotToWatchist from "../../../../shared/AddorNotToWatchist";
import { convertProductPrice } from "../../../../pendingProductsTab/TableProps";
import {
  getRapNet,
  getRapoff,
} from "../../../../products/preview/TenderGenericFunc";
import ReactPlayer from "react-player";
import { getAnnualRapnetPercentage } from "../../../MypageApiService";
import ParcelProductDescription from "../../../../shared/ParcelProductDescription";

const StyledTotalSubProducts = styled.div`
  position: absolute;
  bottom: 0;
  background: ${(props) => props.theme.colors.base.lightGrey};
  width: 100%;
  text-align: center;
  opacity: 0.5;
  color: ${(props) => props.theme.colors.base.black};
`;

const StyledProductImage = styled("img")`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledWrapper = styled(Grid)`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  // text-align: center;
`;

const StyledLabelWrapper = styled.div`
  color: grey;
  display: flex;
  width: 100%;
  font-size: 0.9em;
`;

const StyledSerialNumberLabelWrapper = styled.div`
  text-align: center;
  font-size: 1em;
`;

const StyledValueConatiner = styled.div`
  text-align: center;
`;

const StyledBidAmount = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const StyledRemarkLbl = styled.div`
  margin-left: 0.2em;
  display: flex;
`;

const StyledRemarks = styled(Grid)`
  display: flex;
  align-items: end;
  margin-left: 6px;
`;

const StyledProductCon = styled(Grid)`
 border-bottom: 1px solid ${(props) => props.theme.colors.base.white};
  .marginLeft {
    margin-left: 1%px;
  }

  .padding {
    padding: 0.2em;
    font-size: 1.2em;
  }
  .alignCenter {
    text-align: center;
  }
  .marginRight {
    border-right: 1px solid ${(props) => props.theme.colors.base.white};
  }
  .alignRight {
    text-align: right;
  }
`;

const StyledDescription = styled(Grid)`
  padding: 0.1em;
  widht: 100%;
  display: -webkit-box;
  max-width: 420px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledContDaimond = styled.div`
  padding: 0.2em;
`;

const StyledPriceSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const StyledDescriptionContainer = styled.div`
  display: -webkit-box;
  max-width: 500px;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
interface IProps {
  index: number;
  listItemData: any;
  onCardClickHandler: (product: any) => void;
  fetchProducts: () => void;
  selectedExportId: any[];
  selectItemInGridView: (x: any) => void;
  handleWatchlist: (
    auctionId: string,
    productId: string,
    isWatch: boolean
  ) => void;
  isSellPage: boolean;
}

// let gridIndex: any = [];
const ProductCard = (props: IProps) => {
  const { t } = useTranslation();
  const {
    listItemData,
    onCardClickHandler,
    fetchProducts,
    index,
    selectedExportId,
    selectItemInGridView,
    handleWatchlist,
    isSellPage,
  } = props;

  const {
    medias,
    watched,
    serialNo,
    perId,
    auctionId,
    code,
    carat,
    caratMinimumPrice,
    preBidPrice,
    remarks,
    parcelType,
    subItems,
    noOfPieces,
    currency,
    certificate,
    lab,
    exchangeRates,
    currentBidPrice,
    auctionStartDate,
    state,
    rapnetPercentage,
    caratSellingPrice,
    annualProductState,
    auctionType
  } = listItemData;

  const mediasArr = medias !== undefined ? [...medias] : [];
  let caratMinPrice: string = "";
  let bidAmount: string = "";
  let conversionCaratMinPrice: string = "";
  let conversionBidAmount: string = "";
  let conversionPrebidingMinPrice: string = "";
  let conversionReserAmount: string = "";

  // Selectors
  const previewPageSelectedAuction = useSelector((state: RootState) =>
    selectPreviewSelectedAuction(state)
  );

  const preferredCurrency = localStorage.getItem("preferredCurrency");
  const preferredCurrencySymbol = localStorage.getItem(
    "preferredCurrencySymbol"
  );

  const userCurrencyCode = preferredCurrency;
  const userCurrencySymbol = preferredCurrencySymbol;
  const isMobile = useMediaQuery("(max-width: 1378px)");

  caratMinPrice = getNumberFormat(caratMinimumPrice);
  bidAmount = getNumberFormat(preBidPrice);
  useEffect(() => {
    caratMinPrice = "";
    bidAmount = "";
    return () => localStorage.removeItem("pathname"); // TODO: Handle pathname functionality in redux store.
  }, []);

  const onCardClick = () => {
    onCardClickHandler(listItemData);
  };

  const isParcelTypeDiamond = () => {
    return parcelType === ParcelType.DIAMOND;
  };
  const isParcelTypeParcel = () => {
    return parcelType === ParcelType.PARCEL;
  };
  const isParcelTypeJewellery = () => {
    return parcelType === ParcelType.JEWELLERY;
  };
  const isRemarksAvailable = () => {
    return remarks !== "" && remarks !== undefined && remarks !== null;
  };
  const checkCaratMinPrice = () => {
    return (
      caratMinimumPrice === null ||
      caratMinPrice === "NaN" ||
      caratMinPrice === "0"

    );
  };
  const isDefaultImage = () => {
    return (
      mediasArr === null ||
      mediasArr === undefined ||
      mediasArr[0] === undefined ||
      mediasArr[0] === null ||
      mediasArr[0].presignedUrl === null
    );
  };

  const isSubItemsAvailable = () => {
    return subItems !== undefined && subItems.length > 0;
  };

  if (checkCaratMinPrice()) {
    caratMinPrice = "-";
  } else {
    if (userCurrencyCode !== currency["code"]) {
      let conversionPrice = getNumberFormat(
        Math.round(caratMinimumPrice * exchangeRates[0]["rate"])
      );
      conversionCaratMinPrice = `(${userCurrencySymbol}${conversionPrice})`;
    } else {
      conversionCaratMinPrice = "";
    }
    caratMinPrice = `${currency["symbol"]}${caratMinPrice}`;
  }

  if (preBidPrice === null || bidAmount === "NaN" || bidAmount === "0") {
    bidAmount = "-";
    conversionBidAmount = "";
  } else {
    if (userCurrencyCode !== currency["code"]) {
      let conversion = getNumberFormat(
        Math.round(preBidPrice * exchangeRates[0]["rate"])
      );
      conversionBidAmount = `(${userCurrencySymbol}${conversion})`;
    } else {
      conversionBidAmount = "";
    }
    bidAmount = `${currency["symbol"]}${bidAmount}`;
  }

  const ShowImage = () => {
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return <>
      {medias[0].mediaType === "PHOTO" ? (
        <StyledProductImage src={`${medias[0].presignedUrl}`} alt="" loading="lazy" />
      ) : (
        <>
          {medias[0].mediaType.thumbnailPresignedUrl === null ||
            medias[0].mediaType.presignedUrl === null ? (
            <StyledProductImage
              src={`/images/${isParcelTypeDiamond() || isParcelTypeJewellery()
                ? "default-diamond.png"
                : "default-parcel.png"
                }`}
            ></StyledProductImage>
          ) : (
            <>{mediasArr[0].mediaType === "V360"
              ? <StyledProductImage
                src={`/images/${isParcelTypeDiamond() || isParcelTypeJewellery()
                  ? "default-diamond.png"
                  : "default-parcel.png"
                  }`}
              ></StyledProductImage> :
              <PlayerSlide url={`${medias[0].presignedUrl}`} />
            }</>
          )}
        </>
      )}
    </>
  };


  const CheckBoxrender = () => {
    return (
      <Checkbox
        id={perId}
        name={perId}
        checked={selectedExportId.indexOf(perId) !== -1}
        color="primary"
        style={{ color: "white" }}
        value={index}
        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
        onChange={(e) => {
          selectItemInGridView(perId);
        }}
      />
    );
  };

  const DiamondTitle = () => {
    return (
      <StyledDescriptionContainer>
        <StyledContDaimond>
          <ProductDescription
            truncateLength={isMobile ? 95 : 100}
            data={listItemData}
            isTendor={false}
          />
        </StyledContDaimond>
      </StyledDescriptionContainer>

    );
  };


  const ParcelTitle = () => {
    return (
      <StyledDescriptionContainer>
        <StyledProductCon
          item
          spacing={0}
          container
          xs={12}
        >
          <Grid xs={6} item className="marginRight padding" style={{ fontSize: '0.9em' }}>
            {code}
          </Grid>
          <Grid
            xs={2}
            item
            className="marginRight padding alignCenter"
            style={{ fontSize: '0.9em' }}
          >
            {noOfPieces === 0
              ? "-"
              : `${noOfPieces} ${t("common:preview:pieces")}`}
          </Grid>
          <Grid xs={4} item className="padding alignRight" style={{ fontSize: '0.9em' }}>
            {carat === undefined ? "" : ` ${carat} ${"Ct"}`}
          </Grid>
        </StyledProductCon>

        <StyledDescription item xs={12} className="padding" style={{ fontSize: '1em' }}>
          <ParcelProductDescription
            truncateLength={isMobile ? 88 : 95}
            data={listItemData}
            isTendor={false}
          />
        </StyledDescription>
      </StyledDescriptionContainer>
    );
  };

  const JewelleryTitle = () => {
    return (
      <StyledDescriptionContainer>
        <StyledContDaimond>
          <ProductDescription
            truncateLength={95}
            data={listItemData}
            isTendor={false}
          />
        </StyledContDaimond>
      </StyledDescriptionContainer>
    );
  };

  const HeaderTemplate = () => {
    return (
      <>
        {isParcelTypeDiamond() && <DiamondTitle />}
        {isParcelTypeParcel() && <ParcelTitle />}
        {isParcelTypeJewellery() && <JewelleryTitle />}
      </>
    );
  };

  const RemarksTemplate = () => {
    return (
      <>
        {parcelType === "Diamond" && (
        <div style={{ display: 'flex', gap: 2, flexDirection: "row" }}>
          <span style={{ fontSize: '1.1em' }}>
            {t("common:preview:perItemDescription:remarks") + ": "} {" "}
          </span>
          <StyledRemarkLbl style={{ color: "yellow", fontSize: '1.1em' }} >
            {remarks !== "" &&
              remarks !== undefined &&
              remarks !== null ? (
              <>
                <EllipsisText
                  text={remarks}
                  length={isMobile ? 28 : 42}
                  tail={"..."}
                />
              </>
            ) : (
              ""
            )}
          </StyledRemarkLbl>
        </div>
        )}
      </>
    );
  };

  const WatchListTemplate = () => {
    return (
      <AddorNotToWatchist
        productId={perId}
        auctionId={auctionId}
        watched={watched}
        state={previewPageSelectedAuction?.state}
        fetchData={fetchProducts}
        onWatch={handleWatchlist}
        auctionStartDate={auctionStartDate}
        onCardClick={onCardClick}
      />
    );
  };

  const LeftColTemplate = () => {
    return (
      <>
        <StyledWrapper>
          <StyledSerialNumberLabelWrapper>
            {t("common:preview:productsList:serialNo")}
          </StyledSerialNumberLabelWrapper>
          <StyledValueConatiner>{serialNo}</StyledValueConatiner>
        </StyledWrapper>
        <StyledWrapper>
          <StyledSerialNumberLabelWrapper style={{ color: "blue" }}>
            {lab}
          </StyledSerialNumberLabelWrapper>
          <StyledValueConatiner
            className="fontBold"
            style={{ textAlign: "center", fontSize: "0.5em" }}
          >
            {lab !== "AGT" &&
              lab !== "Agt" &&
              lab !== "" &&
              lab !== undefined ? (
              <a href="#/" className="moveToLandingPageLink">
                {certificate}
              </a>
            ) : (
              <>
                <span style={{ color: "black" }}>{certificate}</span>
              </>
            )}
          </StyledValueConatiner>
        </StyledWrapper>
      </>
    );
  };

  const PlayerSlide = ({ url }: { url: string }) => (
    <ReactPlayer
      width="100%"
      height="100%"
      url={url}
      playing={false}
      controls={true}
      muted={true}
      progressInterval={1000}
      config={{
        file: {
          attributes: {
            controlsList: "nofullscreen",
          },
        },
      }}
    />
  );

  const RightColTemplate = () => {
    return (
      <>
        {isDefaultImage() ? (
          <StyledProductImage
            src={`/images/${isParcelTypeDiamond() || isParcelTypeJewellery()
              ? "default-diamond.png"
              : "default-parcel.png"
              }`}
          ></StyledProductImage>
        ) : (
          <ShowImage />
        )}

        <StyledTotalSubProducts>
          {isSubItemsAvailable() && (
            <span>
              {subItems.length} {t("common:preview:products")}
            </span>
          )}
        </StyledTotalSubProducts>
      </>
    );
  };

  const RapPricingTemplate = () => {
    return (
      <>
        <StyledWrapper item={true} xs={12}>
          <StyledPriceSection>
            <StyledLabelWrapper className="fontBold">
              A. Starting Rap:
            </StyledLabelWrapper>
            <StyledBidAmount>
              <span className="fontBold textWrap">
                {auctionType === "Annual" ? getAnnualRapnetPercentage(listItemData, listItemData.caratMinimumPrice) : getRapNet(rapnetPercentage)}
              </span>
            </StyledBidAmount>
          </StyledPriceSection>
          <StyledPriceSection>
            <StyledLabelWrapper className="fontBold">
              B. Bid Rap:
            </StyledLabelWrapper>
            <StyledBidAmount>
              <span className="fontBold textWrap">
                {getAnnualRapnetPercentage(listItemData, Math.max(listItemData.preBidPrice, listItemData.highestBidPrice))}
              </span>
            </StyledBidAmount>
          </StyledPriceSection>
          <StyledPriceSection>
            <StyledLabelWrapper className="fontBold">
              B. Live Highest Rap:
            </StyledLabelWrapper>
            <StyledBidAmount>
              <span className="fontBold textWrap">
                {auctionType === "Annual" ? getAnnualRapnetPercentage(listItemData, currentBidPrice) : "-"}
              </span>
            </StyledBidAmount>
          </StyledPriceSection>
        </StyledWrapper>
      </>
    );
  };

  const FooterTemplate = () => {
    return (
      <>{isSellPage ? renderSellPriceSection() : renderbuyPriceSection()}</>
    );
  };

  const renderSellPriceSection = () => {
    return (
      <StyledWrapper item={true} xs={12}>
        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Starting Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              {convertProductPrice(listItemData, caratMinimumPrice)}
            </span>
          </StyledBidAmount>
        </StyledPriceSection>
        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Reserve Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              {convertProductPrice(listItemData, caratSellingPrice)}
            </span>
          </StyledBidAmount>
        </StyledPriceSection>
        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Bid Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              {state !== "Available" ? (
                <>{convertProductPrice(listItemData, Math.max(preBidPrice, listItemData.highestBidPrice))}</>
              ) : (
                <>{auctionType === "Annual" ? convertProductPrice(listItemData, Math.max(preBidPrice, listItemData.highestBidPrice)) : `-`}</>
              )}
            </span>
          </StyledBidAmount>
        </StyledPriceSection>
        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Live highest Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              <>{auctionType === "Annual" ? convertProductPrice(listItemData, listItemData.currentBidPrice) : "-"}</>
            </span>
          </StyledBidAmount>
        </StyledPriceSection>

        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Winning Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              {state === "Sold" || state === "Unsold" ? (
                <>{convertProductPrice(listItemData, currentBidPrice)}</>
              ) : (
                <>{auctionType === "Annual" ? annualProductState === "Ended" ? convertProductPrice(listItemData, currentBidPrice) : "-" : `-`}</>
              )}
            </span>
          </StyledBidAmount>
        </StyledPriceSection>
      </StyledWrapper>
    );
  };

  const renderbuyPriceSection = () => {
    return (
      <StyledWrapper item={true} xs={12}>
        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Starting Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              {convertProductPrice(listItemData, caratMinimumPrice)}
            </span>
          </StyledBidAmount>
        </StyledPriceSection>
        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Reserve Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              {state === "Hold" ? (
                <>{convertProductPrice(listItemData, caratSellingPrice)}</>
              ) : (
                <>-</>
              )}
            </span>
          </StyledBidAmount>
        </StyledPriceSection>
        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Bid Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              {state === "Available" ? (
                <> {convertProductPrice(listItemData, Math.max(listItemData.preBidPrice, listItemData.highestBidPrice))}</>
              ) : (
                <>{auctionType === "Annual" ? convertProductPrice(listItemData, Math.max(listItemData.preBidPrice, listItemData.highestBidPrice)) : prebidRender(listItemData)}</>
              )}
            </span>
          </StyledBidAmount>
        </StyledPriceSection>
        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Live highest Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              <>{auctionType === "Annual" ? convertProductPrice(listItemData, listItemData.currentBidPrice) : "-"}</>
            </span>
          </StyledBidAmount>
        </StyledPriceSection>
        <StyledPriceSection>
          <StyledLabelWrapper className="fontBold">
            Winning Price:
          </StyledLabelWrapper>
          <StyledBidAmount>
            <span className="fontBold textWrap">
              {state === "Sold" || state === "Unsold" ? (
                <>{convertProductPrice(listItemData, currentBidPrice)}</>
              ) : (
                <>-</>
              )}
            </span>
          </StyledBidAmount>
        </StyledPriceSection>
      </StyledWrapper>
    );
  };
  // prebid logic

  const prebidRender = (row: any) => {
    if (row.preBidPrice === 0) {
      return convertProductPrice(row, row.highestBidPrice);
    } else {
      if (row.preBidPrice < row.highestBidPrice) {
        return convertProductPrice(row, row.highestBidPrice);
      } else {
        if (row.preBidPrice !== undefined) {
          return convertProductPrice(row, row.preBidPrice);
        } else {
          return convertProductPrice(row, row.highestBidPrice);
        }
      }
    }
  };

  return (
    <Grid>
      {perId !== undefined && (
        <BaseCardLayout
          cardId={serialNo.toString()}
          onCardClick={onCardClick}
          headerTemplate={<HeaderTemplate />}
          remarksTemplate={<RemarksTemplate />}
          checkBoxrender={<CheckBoxrender />}
          watchListTemplate={<WatchListTemplate />}
          pricingTemplate={<RapPricingTemplate />}
          leftColTemplate={<LeftColTemplate />}
          rightColTemplate={<RightColTemplate />}
          footerTemplate={<FooterTemplate />}
        />
      )}
    </Grid>
  );
};

export default ProductCard;
